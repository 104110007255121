import React from 'react';
import Link from 'next/link';
import { UrlLike } from 'next/router';
import * as Pager from '../../domain/Pager';

/**
 * ページャー部品
 */
interface PagerPartsProps {
  className: string;
  disabled: boolean;
  href: UrlLike;
  as: UrlLike;
}

const PagerParts: React.FC<PagerPartsProps> = props => {
  const { className, disabled, href, as, children } = props;

  // 非活性の場合はリンクなし
  if (disabled) {
    return <li className={className}>{children}</li>;
  }

  return (
    <li className={className}>
      <Link href={href} as={as}>
        <a>{children}</a>
      </Link>
    </li>
  );
};

/**
 * ページャー本体
 */
interface PagerProps {
  pager: Pager.Value;
  nodeNum?: number;
  basePathname: string;
  baseAsPath: string;
  baseQuery: { [key: string]: any };
  hash?: string;
}

interface PagerState {
  nodeNum: number;
}

export default class PagerMain extends React.Component<PagerProps, PagerState> {
  constructor(props: PagerProps) {
    super(props);
    this.state = {
      nodeNum: props.nodeNum || 3,
    };
  }

  public shouldComponentUpdate(nextProps: PagerProps): boolean {
    // 画面遷移する前に再描画されてしまうので、リストの中身が更新されたときだけ再描画するようにする
    // 表示件数分投稿されるとリストの中身が変わらずにページだけ変わるので総件数での判定も入れておく
    return !this.props.pager.items.equals(nextProps.pager.items) || this.props.pager.totalCount !== nextProps.pager.totalCount;
  }

  public render(): React.ReactNode {
    const { pager, basePathname, baseAsPath, baseQuery, hash = '' } = this.props;

    // 1件もない時はページャーを表示しない
    if (pager.totalCount === 0) {
      return null;
    }

    const sort = pager.filter.get('sort');
    return (
      <div className="flex flex-col items-center mb-5">
        <div className="flex bg-white text-2xl">
          <ul className="flex h-12 font-medium">
            <PagerParts
              className="w-12 flex border-2 border-pink-500 justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in text-pink-500"
              disabled={pager.page === 1}
              href={{ pathname: basePathname, query: this.makeQuery(baseQuery, pager.page - 1, sort), hash }}
              as={{ pathname: baseAsPath, query: this.makeQuery({}, pager.page - 1, sort), hash }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-left w-6 h-6"
              >
                <polyline points="15 18 9 12 15 6" />
              </svg>
            </PagerParts>
            {this.renderNodes()}
            <PagerParts
              className="w-12 flex border-2 border-pink-500 justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in text-pink-500"
              disabled={pager.page === pager.maxPage}
              href={{ pathname: basePathname, query: this.makeQuery(baseQuery, pager.page + 1, sort), hash }}
              as={{ pathname: baseAsPath, query: this.makeQuery({}, pager.page + 1, sort), hash }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-right w-6 h-6"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </PagerParts>
          </ul>
        </div>
      </div>
    );
  }

  /**
   * @returns {React.ReactNode}
   */
  private renderNodes(): React.ReactNode {
    const { pager, basePathname, baseAsPath, baseQuery, hash = '' } = this.props;
    const maxPage = pager.maxPage;
    const nodeNum = Math.min(this.state.nodeNum, maxPage);
    const half = Math.floor(nodeNum / 2);
    let start = pager.page > half ? pager.page - half : 1;
    let end = start > 1 ? pager.page + half : nodeNum;
    if (maxPage !== 0 && maxPage < end) {
      start = start > end - maxPage ? start - (end - maxPage) : 1;
      end = maxPage;
    }

    const sort = pager.filter.get('sort');
    const pagerNodes = [];
    const currentClassValue =
      'w-12 flex border-2 border-pink-500 justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in text-pink-500';
    const classValue = 'w-12 flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in  bg-pink-600 text-white';
    for (let i = start; i <= end; i += 1) {
      pagerNodes.push(
        <PagerParts
          className={i === pager.page ? classValue : currentClassValue}
          key={i}
          disabled={false}
          href={{ pathname: basePathname, query: this.makeQuery(baseQuery, i, sort), hash }}
          as={{ pathname: baseAsPath, query: this.makeQuery({}, i, sort), hash }}
        >
          {i}
        </PagerParts>,
      );
    }

    return pagerNodes;
  }

  /**
   * クエリパラメータのオブジェクトを作成する
   *
   * @param {{ [key: string]: any }} baseQuery
   * @param {number} page
   * @param {string | undefined} sort
   * @returns {{ [key: string]: any }}
   */
  private makeQuery(baseQuery: { [key: string]: any }, page: number, sort?: number): { [key: string]: any } {
    if (!sort || sort === 0) {
      return Object.assign({}, baseQuery, { page });
    }

    return Object.assign({}, baseQuery, { page }, { sort });
  }
}
