import React from 'react';
import Link from 'next/link';
import { Tabs, Tab } from '../../common/Tabs';

/**
 * トピック一覧のタブ
 */
interface Props {
  currentIndex: number;
  basePathname: string;
  baseAsPath: string;
  baseQuery: { [key: string]: any };
  hash?: string;
}

const TopicListTab: React.FC<Props> = props => {
  const { currentIndex, basePathname, baseAsPath, baseQuery, hash = '' } = props;

  const tabValue = ['更新順', '新着順', '人気順'];
  const tabNodes = tabValue.map((value: string, i: number) => {
    const index = i + 1;
    let className = 'w-full text-center bg-gray-600 inline-block py-2 px-4 rounded-t text-white hover:text-blue-600 font-semibold';
    if (currentIndex === index) {
      className = 'w-full text-center bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-gray-600 font-semibold';
    }
    return (
      <Tab key={index} index={index} width={'1/3'}>
        <Link
          href={{ pathname: basePathname, query: Object.assign({}, baseQuery, { sort: index }), hash }}
          as={{ pathname: baseAsPath, query: { sort: index }, hash }}
        >
          <a className={className}>{value}</a>
        </Link>
      </Tab>
    );
  });

  return <Tabs current={currentIndex}>{tabNodes}</Tabs>;
};

export default TopicListTab;
