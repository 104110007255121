import React from 'react';
import { NextContext } from 'next';
import Utils from '../libs/utils';
import LayoutActionDispatcher from '../modules/commons/LayoutActionDispatcher';
import TopicActionDispatcher from '../modules/top/ActionDispatcher';
import * as Pager from '../domain/Pager';
import SearchContainer from '../components/top/search/Container';
import CategoryRecommendContainer from '../components/category/recommend/Container';
import TopTopicListContainer from '../components/top/topic/Container';
import TopTopicTabContainer from '../components/top/tab/Container';
import TopTopicPagerContainer from '../components/top/pager/Container';
import CategoryListContainer from '../components/category/list/Container';
import FooterSectionContainer from '../components/top/footer/Container';
import HEAD from 'next/head';

class Top extends React.PureComponent {
  public static async getInitialProps(ctx: NextContext) {
    const { store, query, pathname } = ctx;
    const sort = Utils.parseInt(query.sort) || 1;
    const page = Utils.parseInt(query.page) || 1;

    const session = store.getState().commons.session;
    const action = new TopicActionDispatcher(store.dispatch, session);
    await action.fetchList(Pager.Value.fromJS({ page, filter: { sort } }));

    const title = '看護師・看護学生のお悩み掲示板｜ナース専科';
    const layoutAction = new LayoutActionDispatcher(store.dispatch);
    layoutAction.settingLayout({
      header: {
        showTitle: false,
        isTop: true,
        isNewHeader: true,
      },
      footer: {
        showTopLink: false,
        showPostButton: true,
      },
      breadcrumb: {
        data: page <= 1 ? [] : [{ path: '', label: `${page}ページ目` }],
      },
      seo: {
        asPath: pathname,
        pager: store.getState().top.topic,
        title: page <= 1 ? title : `${page}ページ目：${title}`,
        description: 'ナース専科ラウンジは看護に関する質問や相談、悩みを通して看護師・看護学生同士でつながり、助け合い、共に向上していける場です。',
        canonical: page <= 1 ? pathname : `${pathname}?page=${page}`,
      },
    });
  }

  public render(): React.ReactNode {
    return (
      <main className="p-2 lg:w-7/12 lg:mt-1 lg:ml-auto lg:mr-3">
        <HEAD>
          <link href="https://cdn-nurse-senka-assets.nurse-senka.jp/css/tailwind.min.css" rel="stylesheet" />
          <link href="https://use.fontawesome.com/releases/v5.0.6/css/all.css" rel="stylesheet" />
        </HEAD>
        <SearchContainer />
        <CategoryRecommendContainer />
        <TopTopicTabContainer />
        <TopTopicListContainer />
        <TopTopicPagerContainer />
        <CategoryListContainer />
        <FooterSectionContainer />
      </main>
    );
  }
}

export default Top;
