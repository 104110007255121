import React from 'react';
import classNames from 'classnames';

/**
 * タブ部品
 */
interface TabProps {
  index: number;
  isActive?: boolean;
  onClick?: (index: number) => void;
  width: string;
}

export class Tab extends React.PureComponent<TabProps> {
  constructor(props: TabProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(): void {
    if (this.props.onClick) {
      this.props.onClick(this.props.index);
    }
  }

  public render(): React.ReactNode {
    const className = classNames({
      active: this.props.isActive,
    });

    return (
      <li className={`-mb-px mr-1 w-${this.props.width} ${className}`} key={this.props.index} onClick={this.handleClick}>
        {this.props.children}
      </li>
    );
  }
}

/**
 * タブ
 */
interface TabsProps {
  current: number;
}

export const Tabs: React.FC<TabsProps> = props => {
  const nodes = React.Children.map<React.ReactElement<TabProps>, any>(props.children, (child: React.ReactElement<TabProps>) => {
    return React.cloneElement(child, {
      isActive: props.current === child.props.index,
    });
  });

  return <ul className="flex w-full border-b">{nodes}</ul>;
};
