import React from 'react';
import immutable from 'immutable';
import Link from 'next/link';
import * as Category from '../../../domain/Category';

/**
 * カテゴリ一覧の子要素
 */
interface ItemProps {
  category: Category.Entity;
}

const CategoryListItem: React.FC<ItemProps> = props => {
  const href = `/topic/list?category_code=${props.category.code}`;
  const as = `/topic/list/${props.category.code}`;

  return (
    <li className="mb-3">
      <Link href={href} as={as}>
        <a className="text-blue-500 underline">{props.category.name}</a>
      </Link>
    </li>
  );
};

/**
 * カテゴリ一覧
 */
interface ListProps {
  categories: immutable.List<Category.Entity>;
}

const CategoryList: React.FC<ListProps> = props => {
  const CategoryNodes = props.categories.map((category: Category.Entity) => {
    return <CategoryListItem key={category.code} category={category} />;
  });

  return (
    <ul className="block px-2 mt-4 ml-auto mr-auto">
      <li className="mb-3">
        <Link href="/topic/list">
          <a className="text-blue-500 underline">全トピック一覧</a>
        </Link>
      </li>
      {CategoryNodes}
    </ul>
  );
};

export default CategoryList;
