import React from 'react';
import * as Session from '../../../domain/Session';

/**
 * ラウンジトップのタブ一覧
 */
interface Props {
  session: Session.Value;
}

const TopFooterComponent: React.FC<Props> = props => {
  const links = [{ title: 'ラウンジMyページ', href: '/my' }, { title: 'ラウンジとは', href: '/about' }];

  const nodes = links.map((value: any, index: number) => {
    return (
      <p key={index} className="mb-3">
        <a href={value.href}>
          <button className="w-full mr-auto ml-auto py-1 pl-3 bg-green-400 border-solid shadow-md rounded text-left text-sm text-white font-bold">
            {value.title}
            <span className="mr-3 text-white-500 font-bold float-right">▶</span>
          </button>
        </a>
      </p>
    );
  });

  return (
    <section>
      <article>{nodes}</article>
    </section>
  );
};

export default TopFooterComponent;
