import React from 'react';
import EnvConstant from '../../../constants/EnvConstant';
import * as Validation from '../../../domain/Validation';

/**
 * キーワード検索
 */
interface Props {
  validation: Validation.Value;
}

const Search: React.FC<Props> = props => {
  return (
    <form className="flex mt-3 h-10 bg-white rounded-lg border shadow" action={`${EnvConstant.PORTAL_WEB_URL}/search`}>
      <div className="w-4/12 h-10">
        <div className="inline-block relative w-full">
          <select
            name="service"
            className="block bg-white appearance-none w-full hover:border-gray-500 px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option selected={true} value="lounge">
              掲示板
            </option>
            <option value="square">読み物</option>
            <option value="medical_term">看護知識</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-1/12 h-10 border-l-2 text-center">
        <i className="fa fa-search text-xl pt-2 text-gray-400" />
      </div>
      <div className="w-7/12 h-10">
        <div className="w-full">
          <input
            name="keyword"
            className="appearance-none block w-full text-gray-700 py-2 leading-tight focus:outline-none focus:bg-white"
            id="keyword"
            type="text"
            placeholder="キーワードで検索"
          />
        </div>
      </div>
    </form>
  );
};

export default Search;
