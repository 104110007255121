import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Component from './Component';

/**
 * カテゴリ一覧
 */
const mapStateToProps = (state: RootState) => {
  const categories = state.entities.categories.getEntities(state.categories.list.toArray());

  return {
    categories,
  };
};

export default connect(mapStateToProps)(Component);
