import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Component from './Component';

/**
 * ラウンジトップのトピック一覧
 */
const mapStateToProps = (state: RootState) => {
  const session = state.commons.session;
  const pager = state.top.topic;
  const topics = state.entities.topics.getEntities(pager.items.toArray());
  const categories = state.entities.categories;

  return {
    session,
    topics,
    categories,
    pager,
  };
};

export default connect(mapStateToProps)(Component);
