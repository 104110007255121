import React from 'react';
import Link from 'next/link';
import immutable from 'immutable';
import * as Category from '../../../domain/Category';

/**
 * おすすめカテゴリ一覧
 */
interface Props {
  categories: immutable.List<Category.Entity>;
}

const CategoryRecommendComponent: React.FC<Props> = props => {
  const categoryNodes = props.categories.map((category: Category.Entity) => {
    if (category.name === '') {
      return null;
    }

    const href = `/topic/list?category_code=${category.code}`;
    const as = `/topic/list/${category.code}`;

    return (
      <li className="text-pink-400 p-1 w-1/3 float-left text-center" key={category.code}>
        <Link href={href} as={as}>
          <a className="block w-full bg-white border rounded shadow font-bold py-1">{category.name}</a>
        </Link>
      </li>
    );
  });

  return (
    <section className="flex mb-4">
      <ul className="inline-box w-full">{categoryNodes}</ul>
    </section>
  );
};

export default CategoryRecommendComponent;
