import React from 'react';
import { Config } from '../../../constants/constant';
import * as Session from '../../../domain/Session';
import * as Pager from '../../../domain/Pager';
import PagerComponent from '../../common/Pager';

/**
 * ページング
 */
interface Props {
  session: Session.Value;
  pager: Pager.Value;
}

const TopicListPagerComponent: React.FC<Props> = props => {
  const { session, pager } = props;
  const basePathname = '/';
  const baseAsPath = '/';
  const baseQuery = {};
  const hash = session.isSp ? 'topic-list' : '';
  const nodeNum = session.isSp ? Config.PAGER_NODE_NUM_SP : Config.PAGER_NODE_NUM_PC;

  return <PagerComponent pager={pager} nodeNum={nodeNum} basePathname={basePathname} baseAsPath={baseAsPath} baseQuery={baseQuery} hash={hash} />;
};

export default TopicListPagerComponent;
