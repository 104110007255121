import React from 'react';
import { Config } from '../../../constants/constant';
import * as Session from '../../../domain/Session';
import * as Pager from '../../../domain/Pager';
import Tab from '../../topic/list/Tab';

/**
 * ラウンジトップのタブ一覧
 */
interface Props {
  session: Session.Value;
  pager: Pager.Value;
}

const TopTopicTabComponent: React.FC<Props> = props => {
  const { session, pager } = props;
  const sort = pager.filter.get('sort');
  const basePathname = '/';
  const baseAsPath = '/';
  const baseQuery = {};
  const hash = session.isSp ? 'topic-list' : '';

  return (
    <section>
      <Tab
        currentIndex={Config.TOPIC_SORT_MAP.has(sort) ? sort : 1}
        basePathname={basePathname}
        baseAsPath={baseAsPath}
        baseQuery={baseQuery}
        hash={hash}
      />
    </section>
  );
};

export default TopTopicTabComponent;
