import React from 'react';
import immutable from 'immutable';
import * as Category from '../../../domain/Category';
import * as Topic from '../../../domain/Topic';
import { ListNav, ListItem } from '../../common/ListNav';
import { ListSecondLine as StyledLine } from '../../../styles/topic';
import { ListEmpty as StyledEmpty } from '../../../styles/common';
import Icon from './Icon';

/**
 * トピック一覧の子要素
 */
interface ItemProps {
  showLastUpdatedAt: boolean;
  topic: Topic.Entity;
  category: Category.Entity;
}

const TopicListItem: React.FC<ItemProps> = props => {
  const { showLastUpdatedAt, topic, category } = props;
  const secondLineNodes = [
    <StyledLine key="second_line_1">{topic.value.since(showLastUpdatedAt)}</StyledLine>,
    <StyledLine key="second_line_2">
      {topic.value.formatCommentCount}
      コメント
    </StyledLine>,
    <StyledLine key="second_line_3">
      {topic.value.formatAccessCount}
      アクセス
    </StyledLine>,
  ];

  return (
    <ListItem
      href={`/topic/detail?topic_id=${topic.identifier}`}
      as={`/topic/detail/${topic.identifier}`}
      tmbClassName={`icon-l ${category.className}`}
      secondLine={secondLineNodes}
    >
      {topic.value.title}
      <Icon topic={topic} />
    </ListItem>
  );
};

/**
 * トピック一覧
 */
interface ListProps {
  showLastUpdatedAt: boolean;
  topics: immutable.List<Topic.Entity>;
  categories: Category.EntityList;
  emptyMessage: string;
  showBorder: boolean;
}

const TopicList: React.FC<ListProps> = props => {
  const { showLastUpdatedAt, topics, categories, emptyMessage, showBorder } = props;
  if (topics.isEmpty()) {
    return <StyledEmpty>{emptyMessage}</StyledEmpty>;
  }

  const TopicNodes = topics.map((topic: Topic.Entity) => {
    return (
      <TopicListItem
        key={topic.identifier}
        showLastUpdatedAt={showLastUpdatedAt}
        topic={topic}
        category={categories.getEntity(topic.value.categoryCode)}
      />
    );
  });

  return <ListNav showBorder={showBorder}>{TopicNodes}</ListNav>;
};

export default TopicList;
