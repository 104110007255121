import React from 'react';
import immutable from 'immutable';
import * as Session from '../../../domain/Session';
import * as Pager from '../../../domain/Pager';
import * as Category from '../../../domain/Category';
import * as Topic from '../../../domain/Topic';
import List from '../../topic/list/List';

/**
 * ラウンジトップのトピック一覧
 */
interface Props {
  session: Session.Value;
  topics: immutable.List<Topic.Entity>;
  categories: Category.EntityList;
  pager: Pager.Value;
}

const TopTopicListComponent: React.FC<Props> = props => {
  const { topics, categories, pager } = props;
  const sort = pager.filter.get('sort');

  return (
    <section className="block bg-white border border-white border-t-none shadow mb-3 px-3 pb-3">
      <article className="w-full">
        <List
          showBorder={false}
          showLastUpdatedAt={sort !== 2 && sort !== 3}
          topics={topics}
          categories={categories}
          emptyMessage="投稿されたトピックはありません"
        />
      </article>
    </section>
  );
};

export default TopTopicListComponent;
