import React from 'react';
import immutable from 'immutable';
import * as Category from '../../../domain/Category';
import List from './List';

/**
 * カテゴリからトピックを探す
 */
interface Props {
  categories: immutable.List<Category.Entity>;
}

const CategoryListComponent: React.FC<Props> = props => {
  return (
    <section className="block bg-white border shadow my-3 p-3">
      <article className="w-full">
        <h2 className="text-sm">
          <span className="bg-green-300 text-white mr-3 p-1 px-3 rounded">カテゴリからトピックを探す</span>
          掲示板
        </h2>
        <List categories={props.categories} />
      </article>
    </section>
  );
};

export default CategoryListComponent;
