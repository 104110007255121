import * as Session from '../../domain/Session';
import * as Pager from '../../domain/Pager';
import RepositoryFactory from '../../repositories/RepositoryFactory';
import TopRepository from '../../repositories/TopRepository';
import { actionCreators, Action } from './topic';

/**
 * ラウンジトップのトピック一覧のアクション
 */
export default class TopActionDispatcher {
  private dispatch: (action: Action) => Action;
  private repository: TopRepository;

  constructor(dispatch: (action: Action) => Action, session: Session.Value) {
    this.dispatch = dispatch;
    this.repository = RepositoryFactory.createTopRepository(session);
    this.fetchList = this.fetchList.bind(this);
  }

  /**
   * トピックのリストを取得して表示する
   *
   * @returns {Promise<void>}
   */
  public async fetchList(pager: Pager.Value): Promise<void> {
    this.dispatch(actionCreators.requestFetchTopTopicList(pager));
    const entityList = await this.repository.findAllTopic(pager);
    this.dispatch(actionCreators.doneFetchTopTopicList(entityList));
  }
}
