import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Component from './Component';

/**
 * キーワード検索
 */
const mapStateToProps = (state: RootState) => {
  const session = state.commons.session;
  const validation = state.validations.search;

  return {
    session,
    validation,
  };
};

export default connect(mapStateToProps)(Component);
