import React from 'react';
import Link from 'next/link';

/**
 * リスト表示の子要素
 */
interface ListItemProps {
  href: string;
  as?: string;
  tmbClassName?: string;
  secondLine?: React.ReactNode;
}

export const ListItem: React.FC<ListItemProps> = props => {
  return (
    <li className="mb-3">
      <Link href={props.href} as={props.as}>
        <dl>
          <dt className="flex">
            <span className="truncate">
              <span className="border-solid border-2 border-gray-500 bg-white h-4 w-4 rounded-full inline-block" />
              <b className="border-b-2 border-green-300">{props.children}</b>
            </span>
            <span className="inline-block align-bottom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-right w-6 h-6"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </span>
          </dt>
          <dd className="ml-5">
            <ul className="text-xs flex">{props.secondLine ? <li className="mr-3">{props.secondLine}</li> : null}</ul>
          </dd>
        </dl>
      </Link>
    </li>
  );
};

/**
 * リスト表示
 */
interface Props {
  showBorder: false | true;
}

export const ListNav: React.FC<Props> = props => {
  if (props.showBorder) {
    return <ul className="block border-l border-r border-gray-300 ml-auto mr-auto px-2 py-4">{props.children}</ul>;
  }

  return <ul className="block px-2 mt-4 ml-auto mr-auto">{props.children}</ul>;
};
