import React from 'react';
import * as Topic from '../../../domain/Topic';
import { Icon as Styled } from '../../../styles/topic';

/**
 * NEW, UP アイコン
 */
interface Props {
  topic: Topic.Entity;
}

const Icon: React.FC<Props> = props => {
  const isNew = props.topic.isNew();
  const isUpdated = props.topic.isUpdated();

  if (!isNew && !isUpdated) {
    return null;
  }

  if (isNew) {
    return <Styled name="new">NEW</Styled>;
  }

  return <Styled name="up">UP</Styled>;
};

export default Icon;
