import styled from './styled';
import * as mixin from './mixin';

/**
 * トピック一覧のコメント数、アクセス数
 */
export const ListSecondLine = styled('span')`
  margin-right: 10px;
`;

/**
 * トピック投稿フォーム
 */
export const Form = styled('div')`
  margin: 10px 0 0;

  dt > span {
    margin-left: 5px;
  }

  dd > p {
    min-width: 48%;
    min-height: calc(1.6rem * 1.5);
    display: inline-block;
    line-height: 1.5;
  }
`;

/**
 * トピック投稿フォームのカテゴリ
 */
export const FormCategory = styled('div')`
  label {
    width: 50%;
    display: inline-block;
    line-height: 2;

    input {
      margin-right: 5px;
    }
  }
`;

/**
 * トピック投稿フォームの任意項目インナー
 */
export const FormOptInner = styled('div')`
  margin: 10px;
`;

/**
 * トピック詳細
 */
export const Detail = styled('div')`
  padding: 10px;
  ${mixin.fontSize(16)};
  line-height: 1.5;
  word-wrap: break-word;

  & > p {
    margin: 0;
    min-height: calc(1.6rem * 1.5);
  }
`;

/**
 * トピック詳細のユーザプロフィール、カテゴリリンク
 */
export const UserCategory = styled('div')`
  margin: 10px 0;

  div:first-child {
    width: 60%;
    display: inline-block;
  }

  div:last-child {
    width: 40%;
    display: inline-block;
    text-align: right;
  }
`;

/**
 * コメント受付終了、違反報告リンク
 */
export const Links = styled('div')`
  margin: 10px 0;

  p {
    margin-right: 10px;
    display: inline-block;
  }
`;

/**
 * トピック詳細のボタン群
 */
export const ActionBox = styled('div')`
  background: ${props => props.theme.moderateColor};
  color: ${props => props.theme.baseTxtColor};

  hr {
    border-top: 1px solid ${props => props.theme.borderColor};
    border-bottom: 1px solid #fff;
  }
`;

/**
 * トピック詳細の sns リンク
 */
export const Sns = styled('div')`
  padding: 15px 0;
  text-align: center;

  span {
    margin: 0 3px;
  }
`;

/**
 * トピック詳細の広告
 */
export const AdSense = styled('div')`
  margin: 15px 0 10px;
  text-align: center;

  ins {
    margin: 0 3px;
    display: inline-block;
    width: 300px;
    height: 250px;
  }
`;

/**
 * トピック詳細のゲストのとき
 */
export const Guest = styled('div')`
  padding: 10px;
  background: #fdeef3;
`;

export const GuestInner = styled('div')`
  padding: 10px;
  background: #fff;
  border-radius: 6px;
`;

export const GuestCharBox = styled('div')`
  padding-top: 10px;
  ${mixin.fontSize(15)};
  text-align: center;

  span {
    ${mixin.fontSize(22)};
  }
`;

export const GuestBtnBox = styled('div').attrs({
  className: 'btn-box-h2',
})`
  .icon-s.key::before,
  .icon-s.person::before {
    ${mixin.fontSize(18)};
    margin-bottom: 5px;
  }
`;

/**
 * New, Up アイコン
 */
export interface IconProps extends React.HTMLProps<HTMLElement> {
  name: string;
}

export const Icon = styled('span')<IconProps>`
  margin-left: 5px;
  display: inline-block;
  font-family: Verdana, Geneva, sans-serif;
  color: ${props => (props.name === 'new' ? props.theme.btnHighColor : props.theme.btnMiddleColor)};
  ${mixin.fontSize(10)};
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
`;
