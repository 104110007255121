import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Component from './Component';

/**
 * ラウンジトップのタブ一覧
 */
const mapStateToProps = (state: RootState) => {
  const session = state.commons.session;
  const pager = state.top.topic;

  return {
    session,
    pager,
  };
};

export default connect(mapStateToProps)(Component);
