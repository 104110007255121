import React from 'react';
import * as Session from '../../../domain/Session';
import * as Validation from '../../../domain/Validation';
import Search from './Search';

/**
 * キーワード検索ルートコンポーネント
 */
interface Props {
  session: Session.Value;
  validation: Validation.Value;
}

export default class SearchComponent extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render(): React.ReactNode {
    return <Search validation={this.props.validation} />;
  }
}
