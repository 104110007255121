import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Component from './Component';

/**
 * おすすめカテゴリ一覧
 */
const mapStateToProps = (state: RootState) => {
  const session = state.commons.session;
  const categories = state.entities.categories.recommendedEntities(session.user.experience);

  return {
    categories,
  };
};

export default connect(mapStateToProps)(Component);
